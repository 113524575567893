import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaShare, FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
// import { Helmet } from 'react-helmet';
const Enquiry = () => {

    const api_url = process.env.REACT_APP_API_URL;


    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [show, setShow] = useState(false);
    const [mySpinner, setMySpinner] = useState(false);
    const handleClose = () => {
        setShow(false);
       
      }
    const [pending, setPending] = useState(true);
    const [message, setMessage] = useState('');


    const [showReply, setShowReply] = useState(false);
    const [Id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [reply, setReply] = useState('');


    const notify = () => toast.success("Enquiry Reply SuccessFully", { position: "top-center" });

    const handleReplyShow = (id) => {
        setShowReply(true);
        data?.filter((data) => data.id === id)?.map((data) => {
            setId(data.id);
            setName(data.name);
            setEmail(data.email);
            setSubject(data.subject);
            setReply(data.replies);
        })

    }

    const handleReply = () => {
        setShowReply(false);
        setReply('');
        reset({
        
            replies : ""
      })
    }

    const getData = () => {
       axios.get(`/getEnquiry`).then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data);
            
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    useEffect(() => {
        getData();
        
    }, []);

    const handleShow = (msg) => {
        setShow(true);
        setMessage(msg);
    }

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post(`/enquiryStatus`, {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                window.location.reload(false);
               console.log(response.data);
               statussuccess();
               setMySpinner(false);
            }
        }).catch((err) => {
            setMySpinner(false);
            console.log(err);
            CustomerExist();
        })


    }
    
    const CustomerExist = () => toast.error("Customer Already Exist", { position: "top-center" });
    const statussuccess = () => toast.success("Enquiry Status update Successfully", { position: "top-center" });
    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: (row) => row.name,
                width: '150px',
                
            },
            {
                name: "Email",
                selector: (row) => row.email,
                width: '250px',
               
            },


            {
                name: "Phone",
                selector: (row) => row.phone,
                width: '150px',
                
            },
            {
                name: "Date",
                selector: row => <span>{row.created_at ? moment(row.created_at).format('DD-MM-YYYY') : null} </span>,
                 width: '135px',
              },
            {
              name: "Subject",
              selector: (row) => row.subject,
             width: '150px',
            },
          {
            name: "Message",
            selector: (row) => <Button className='Add_btn_' onClick={() => handleShow(row.message)} ><MdMessage /></Button>,
            width: '100px',

        },
            
            {
                name: "Action",
                selector: row => <Button className='Add_btn_'
                    onClick={() => handleReplyShow(row.id)}
                ><FaShare /></Button>,
                right: true

            },

        ],

    };


    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }

    

    useEffect(() => {
        // setData(newData.filter(data => data.status == 0));
       
    }, [ ]);

    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        axios.post(`/enquiryReply`, {
            id: Id,
            reply: reply,
            email: email,
            name: name,
            subject: subject,
            

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                handleReply();


            }
        })


    }

    return (
        <>

       

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

            <section id="main-content"
                style={pending ? { opacity: '0.5' } : { opacity: '1' }}
            >
                  <ToastContainer />
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Enquiry</span>
                            <section className="card m-3">


                                {/* <div className="selectoption mt-4">

                                    <strong >Status :-</strong>
                                    <select className="status select_status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                      
                                        <option value="0">Pending</option>
                                        <option value="1">Completed</option>
                                        <option value="2">Rejected</option>
                                        <option value="">All</option>
                                    </select>



                                </div> */}

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>

            <Modal show={show} onHide={handleClose} style={{zIndex:"9999"}}>
                <Modal.Header className='justify-content-between'>
                    <span>Message</span>
                    <Button className='Add_btn_' onClick={() => handleClose()}><FaTimes /></Button>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>{message}</Modal.Title>

                    {/* <Model.Text>{message}</Model.Text> */}
                </Modal.Body>

            </Modal>


            <Modal show={showReply} onHide={handleReply} style={{zIndex:"9999"}}>
                <Modal.Header closeButton>
                    <Modal.Title>Reply Enquiry</Modal.Title>

                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>

                        <Form.Group className="mb-3" >
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name"
                                disabled
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"
                                disabled
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Subject</Form.Label>
                            <Form.Control type="text" placeholder="Enter Loan Name"
                                disabled
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Reply</Form.Label>
                            <Form.Control rows={5} as="textarea" placeholder="Type Your Message"
                                value={reply}
                                {...register("reply", {
                                    required: true,
                                    onChange: (e) => setReply(e.target.value)
                                })}
                            />
                          {errors.reply && <div className='errMsg'>Please Enter Message</div>}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleReply}>
                            Close
                        </Button>
                        <Button className='Add_btn_' type='submit'>
                            Reply
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>





        </>
    )
}

export default Enquiry