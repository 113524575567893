import React, { useState, useEffect } from 'react';
import './Login.css';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAdmin } from '../../../store/features/authSlice';

// import { Helmet } from 'react-helmet';
const Login = () => {

const dispatch = useDispatch();
  // const api_url = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFEmail('');
  }
  const handleShow = () => setShow(true);
  const [authDetails, setAuthDetails] = useState([]);
  const [fEmail, setFEmail] = useState('');
  const [fEmailErr, setFEmailErr] = useState('');



  const notify = () => toast.info('Successfully Login', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notifyFor = () => toast.info('Password Successfully Send', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });




  const loginError = (err) => toast.error(`${err}`, {
    position: "top-center",
    autoClose: 5023,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });



  const { register, handleSubmit, formState: { errors } } = useForm();


  const onSubmit = () => {
    axios.post(`/adminLogin`, { email: email, password: password }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setAuthDetails(response.data.data);
        localStorage.setItem("adminAuth", JSON.stringify(response.data.data));
        dispatch(setAdmin({ adminAuth: JSON.stringify(response.data.data), auth: true }))
        notify();
        navigate('/');
        window.location.reload(false);
      }

    }).catch((err) => {
      loginError(err.response.data.message);
    })

  }

  const handleForgetPass = () => {
    if (fEmail == "") {
      setFEmailErr('Please Enter email address');
    } else {
      submitForgetPass();
    }

  }

  const submitForgetPass = () => {
    axios.post(`/forgotPass`, {
      email: fEmail,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        console.log(response.data.data);
        window.location.reload(false);
        notifyFor();
      }
    }).catch((err) => {
      loginError(err.response.data.message);
      console.log(err);
    })

  }
  const getData = () => {
   axios.get(`/adminProfile`).then((response) => {
      console.log(response.data);
      setLogo(response.data.data[0].logo)
      setData(response.data.data)
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <Helmet> */}
      <title>Pathway | Login</title>
      {/* </Helmet> */}
      <section className='_login_admin' >
        <ToastContainer />
        <div className='_login'>

          <div className='container-fluid'>

            <div className='_login_logo text-center'>
              <img style={{ width: "100px" }} src={`assets/logo/${logo}`} alt="logo" />
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}  >
              <Form.Group className="mb-3" >
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" autoComplete="off" value={email} {...register("email", { required: true, onChange: (e) => setEmail(e.target.value) })} />

                {errors.email && <p className='errMeg'>Please Enter Your Email</p>}

              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Password</Form.Label>
                <InputGroup>

                  <Form.Control type={showPassword ? "text" : "password"} placeholder='Enter Password' value={password} {...register("password", { required: true, onChange: (e) => setPassword(e.target.value) })} />
                  <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FaEyeSlash /> : <FaEye />}</InputGroup.Text>

                </InputGroup>
                {errors.password && <p className='errMeg'>Please Enter Your Password</p>}
              </Form.Group>
              <div className='text-center'>
                <Button variant="primary" type="submit" className='_submit_form'>
                  SIGN IN
                </Button></div>
              <div className='forpass text-center'><p style={{ cursor: 'pointer' }} onClick={handleShow}>Forgot your password ?</p></div>
            </Form>


          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <ToastContainer />
          <Modal.Header closeButton className='adminpass'>
            <Modal.Title>Forget Your Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" value={fEmail}
                  onChange={(e) => setFEmail(e.target.value)} />
                <p className='errMeg'>{fEmailErr}</p>
              </Form.Group>
              <div className='text-center'>
                <Button variant="primary" onClick={handleForgetPass} className='Add_btn_ _submit_form'>
                  SIGN IN
                </Button></div>

            </Form>
          </Modal.Body>

        </Modal>
      </section>
    </>
  )
}

export default Login