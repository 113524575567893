import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';


const Consessions = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [pending, setPending] = useState(true);


    const notify = () => toast.success("Edit SuccessFully");


    const getData = () => {
       axios.get(`/getprivacy`).then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        });
    }

    useEffect(() => {
        getData();

    }, [])

    let validationRules = { Today: { required: true } };
    const handleUpdate = () => {
        axios.post(`/termsPrivacy`, { concession: updatedContent }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                window.location.reload(true);
            }

        })
    }

    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <ToastContainer />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="cardSectionBorder">
                                <span className='p-3'>Consession</span>
                                <div className='p-3'>
                                    <TextEditor data={data?.map((data) => data.concession).toString()} setUpdatedContent={setUpdatedContent} validationRules={validationRules} />
                                </div>

                                <hr></hr>
                                <div>
                                    <Button className='Add_btn_' onClick={handleUpdate}>Update</Button>
                                </div>


                            </section>

                        </div>
                    </div>
                </section>

            </section>

        </>
    )
}

export default Consessions