import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
// import Button from '@mui/material/Button';
import { Button } from 'react-bootstrap';
const Setting = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [adminProfile, setAdminProfile] = useState([]);
    const [id, setId] = useState('');

    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('')
    const [pending, setPending] = useState(true);
    const [password, setPassword] = useState('');
    const [clintId, setClintId] = useState('');
    const [clintSecret, setClintSecret] = useState('');

    const getData = () => {
        axios.get(`/getAdminSetting`).then((response) => {
            setPending(false);
            setAdminProfile(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {

        adminProfile?.map((admin) => {

            setEmail(admin.smtp_email);
            setPassword(admin.smtp_password);
            setAmount(admin.minimun_amt)
            setClintId(admin.paypal_client);
            setClintSecret(admin.paypal_secret);

        })
    }, [adminProfile]);

    const notify = () => toast.success("Setting Update SuccessFully", { position: "top-center" });

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);
        axios.post(`/updateSetting`, {
            id: id,
            smtp_email: email,
            smtp_password: password,
            global_script: '',
            minimun_amt: amount,
            paypal_client: clintId,
            paypal_secret: clintSecret,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);

            }

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Setting</span>
                            <section className="card m-3">
                                {/* <header className="card-header cardHeader_section">
                                    <div className="row">
                                        <div className="col-sm-12 text-center"><b>Setting</b></div>
                                    </div>
                                </header> */}
                                <div className="card-body">
                                    <ToastContainer />
                                    <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>

                                        <div className="col-md-4">
                                            <label className="form-label">SMTP Email</label>
                                            <input type="email" className="form-control"
                                                value={email}
                                                //  onChange={(e) => setDummy(e.target.value)}
                                                {...register("Email", { required: false, onChange: (e) => setEmail(e.target.value) })} />
                                            {errors.Email && <div className='errMsg'>Please Enter the Your Email</div>}
                                        </div>

                                        <div className="col-md-4">
                                            <label className="form-label">SMTP Password</label>
                                            <input type="password" className="form-control" placeholder=""
                                                value={password}
                                                {...register("Password", { required: false, onChange: (e) => setPassword(e.target.value) })}
                                            />
                                            {/* {errors.Contact && <div className='errMsg'>Please Enter the Your Contact Number</div>} */}
                                        </div>

                                        <div className="col-md-4">
                                            <label className="form-label">Appointment Amount</label>
                                            <input type="number" className="form-control"
                                                value={amount}
                                                //  onChange={(e) => setDummy(e.target.value)}
                                                {...register("amount", { required: false, onChange: (e) => setAmount(e.target.value) })} />
                                            {errors.amount && <div className='errMsg'>Please Enter amount</div>}
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Client Id</label>
                                            <input type="text" className="form-control"
                                                value={clintId}
                                                //  onChange={(e) => setDummy(e.target.value)}
                                                {...register("clintId", { required: false, onChange: (e) => setClintId(e.target.value) })} />
                                            {errors.clintId && <div className='errMsg'>Please Enter Client Id</div>}
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label">Client Secret</label>
                                            <input type="text" className="form-control"
                                                value={clintSecret}
                                                //  onChange={(e) => setDummy(e.target.value)}
                                                {...register("clintSecret", { required: false, onChange: (e) => setClintSecret(e.target.value) })} />
                                            {errors.clintSecret && <div className='errMsg'>Please Enter Client Secret</div>}
                                        </div>



                                        <div className="col-12 updateabout" >

                                            <Button variant="contained" type="submit" className='Add_btn_'>
                                                Update
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Setting