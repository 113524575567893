import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Appointments = () => {


    // const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [mySpinner, setMySpinner] = useState(false);
    const [pending, setPending] = useState(true);
    const [showRefund, setShowRefund] = useState(false);
    const [refundId, setRefundId] = useState('');
    const [refundAmount, setRefundAmount] = useState('');
    const [paidAmount, setPaidAmount] = useState('');

    const { adminAuth, auth } = useSelector((state) => state?.auth);


    const handleClose = () => {
        setShowRefund(false);
        setRefundAmount('');
        setRefundId('');
        reset({
            refundAmount: '',
        })

    }

    const getData = () => {
        axios.get(`/getAllAppointment`).then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data);

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    useEffect(() => {
        getData();
    }, []);




    useEffect(() => {
        setData(newData?.filter(data => data.status == 1));
    }, [newData])




    const successNotify = (msg) => toast.success(`${msg}`, { position: "top-center" });
    const successRefundNotify = () => toast.success("Refund Successful", { position: "top-center" });
    const successRefundErr = (err) => toast.error(`${err}`, { position: "top-center" });

    const handleStatus = (e) => {

        let id = e.target.id;
        let status = e.target.value;
        let ids = e.target.name

        if (status == 4) {
            axios.post(`/completedSlot`, {
                unique_id: id,
                // id: ids,
                // status: status,
            }, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                if (response.status === 200) {
                    successNotify("Appointment completed Successfully");
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 2000);
                }
            }).catch((err) => {
                console.log(err);
            })
        } else if (status == 3) {
            axios.post(`/slotCancel`, {
                unique_id: id,
                status_mark: JSON.parse(adminAuth)[0]?.id == 1 ? 1 : 2
                // status: status,
            }, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                if (response.status === 200) {
                    successNotify("Appointment cancelled Successfully");
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 2000);

                }
            }).catch((err) => {
                console.log(err);
            })
        } else {

        }

    }


    // const handleRefundChange = (id, amount) => {
    //     setRefundId(id);
    //     setShowRefund(true);
    //     setPaidAmount(amount)

    // }

    const { register, handleSubmit, reset, formState: { errors } } = useForm();


    const handleRefund = (id) => {
        setPending(true);
        axios.post(`/paymentRefundApoint`, {
            unique_id: id,
            // amount: refundAmount

        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleClose();
                successRefundNotify();
                setPending(false);
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
        }).catch((err) => {
            handleClose();
            setPending(false);
            console.log(err);
            successRefundErr("Something went wrong");
        })
    }


    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },

            {
                name: "Unique Id",
                selector: (row) => row.unique_id,
                width: '200px',
            },

            {
                name: "Name",
                selector: (row) => row.name,
                width: '150px',

            },
           
            {
                name: "Appointment Date",
                selector: (row) => row.dateSlotShow,
                width: '200px',
            },
            {
                name: "Time",
                selector: (row) => row.timeSlotShow,
                width: '200px',
            },

            {
                name: "Email",
                selector: (row) => row.email,
                width: '250px',

            },

            {
                name: "Service Type",
                selector: (row) => <>{row.service_type == 1 ? 'The Control System Consultation (2hrs)' : row.service_type == 2 ? 'The Control System (1hr)' :
                    row.service_type == 3 ? 'Weight Release Programme 1st Session (2hrs)' : row.service_type == 4 ? 'Weight Release Sessions Further Sessions (1hr)' :
                        row.service_type == 5 ? 'Free Consultation (15min)' : row.service_type == 6 ? 'Smoking Cessation (1hr)' : row.service_type == 7 ? 'Overcoming Addiction (1hr)' :
                            row.service_type == 8 ? 'Overcoming Gambling  (1hr)' : row.service_type == 9 ? 'Menopause- (2hrs)' : row.service_type == 10 ? 'Hypno Gastric Band - (1.30hrs)' :
                                row.service_type == 11 ? 'Hypno Birthing & Pregnancy- (1.30hrs)' : row.service_type == 12 ? 'Hypnosis session (1.30hrs)' : null}</>,
                width: '250px',

            },


            {
                name: "Phone",
                selector: (row) => row.phone,
                width: '150px',

            },

            {
                name: "Amount",
                selector: (row) => row.amount,
                width: '150px',
            },

            // tnx_id
            {
                name: "Transaction Id",
                selector: (row) => row.tnx_id,
                width: '250px',
            },

            {
                name: "Booking Date",
                selector: row => <span>{row.created_at ? moment(row.created_at).format('YYYY-MM-DD') : null} </span>,
                width: '200px',
              },
            // dateSlotShow
            // timeSlotShow

            {
                name: "Status",
                selector: row => <>
                    <Form.Select aria-label="Default select example"
                        value={row.status} id={row.unique_id} name={row.slot_ids}

                        disabled={row.status == 1 ? false : true}
                        onChange={(e) => handleStatus(e)}
                    >
                        <option value="1">Confirm</option>
                        <option value="3">Cancelled/Reschedule</option>
                        <option value="4">Complete</option>
                    </Form.Select >

                </>,
                width: '200px'

            },

            {
                name: "Refund",
                selector: row => <>
                    {row.refund_status == 1 && row.amount > 0 ?
                        <Button onClick={() => handleRefund(row.unique_id)} className='Add_btn_'>Refund</Button>
                        : row.refund_status == 2 ? <span className='bg-success text-white p-3' >Refunded</span> : null}
                </>,
                width: '150px',
            },

            // {
            //     name: "Action",
            //     selector: row => <Button className='Add_btn_'
            //     // onClick={() => handleReplyShow(row.id)}
            //     ><FaShare /></Button>,
            //     right: true

            // },

        ],

    };



    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }

    }


    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

            <section id="main-content"
                style={pending ? { opacity: '0.5' } : { opacity: '1' }}
            >
                <ToastContainer />
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Appointments</span>
                            <section className="card m-3">


                                <div className="selectoption mt-4">

                                    <strong >Status :-</strong>
                                    <select className="status select_status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >

                                        <option value="1">Confirm</option>
                                        <option value="3">Cancelled</option>
                                        <option value="4">Complete</option>
                                        <option value="">All</option>

                                    </select>

                                </div>

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>


            {/* <Modal show={showRefund} onHide={handleClose} style={{ zIndex: '9999' }}>
                <Form onSubmit={handleSubmit(handleRefund)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Refund</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group md="4" >
                            <h4 className='text-center'>Paid Amount : £{paidAmount}</h4>
                            <Form.Label>Refund Amount</Form.Label>
                            <Form.Control value={refundAmount}
                                type="number" placeholder='Please Enter Refund Amount'
                                {...register("refundAmount", {
                                    required: true,
                                    min: 1,
                                    max: paidAmount,
                                    onChange: (e) => setRefundAmount(e.target.value)
                                })}

                            />
                            {errors.refundAmount && errors.refundAmount.type === "required" && <div className='errMsg pt-2'>Please Enter Refund Amount</div>}
                            {errors.refundAmount && errors.refundAmount.type === "min" && <div className='errMsg pt-2'>Please Enter Minimum £1</div>}
                            {errors.refundAmount && errors.refundAmount.type === "max" && <div className='errMsg pt-2'>Please Enter Maximum £{paidAmount}</div>}

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary" className='Add_btn_'>
                            Refund
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}

        </>
    )
}

export default Appointments