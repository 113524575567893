import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaEdit, FaShare, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const SlotsAdmin = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [allConsultant, setAllConsultant] = useState([])
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState({});
    const [showmessage, setShowMessage] = useState(false);

    const { adminAuth, auth } = useSelector((state) => state?.auth);

    const [dateData, setDateData] = useState('');
    const [timeData, setTimeData] = useState('');
    const [endData, setEndData] = useState('');



    const handleClose = () => {
        setShow(false);

    }
    const handleMessage = () => {
        setShowMessage(false);
        window.location.reload(false);

    }
    const handleMessageshow = () => {
        setShowMessage(true);
    }

    const handleShow = () => {
        setShow(true);

    }

    const [pending, setPending] = useState(true);
    const [slotDate, setSlotDate] = useState('');
    const [end_Date, setEnd_Date] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [consultantIds, setConsultantIds] = useState('');

    const [updateSlotDate, setUpdateSlotDate] = useState('');
    const [updateSlotTime, setUpdateSlotTime] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [curSlotId, setCurSlotId] = useState('');
    const [service_type, setService_type] = useState('');
    const [consultantId, setConsultantId] = useState('');
    const [slot_Date, setSlot_Date] = useState('');
    const [slotStatus, setSlotStatus] = useState('')
    const [endTimeShow, setEndTimeShow] = useState('');
    const [updateData, setUpdateData] = useState({})



    const getData = () => {

        axios.get(`/allconsultants`).then((response) => {
            setAllConsultant(response.data.data);
            setPending(false);

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
        if (JSON.parse(adminAuth)[0]?.id != 1) {
            setConsultantId(JSON.parse(adminAuth)[0]?.id)
        }
    }, []);


    useEffect(() => {

        // setConsultantIds
        if (service_type != '') {
            axios.get(`/getallSlots/${JSON.parse(adminAuth)[0]?.id == 1 ? consultantIds : JSON.parse(adminAuth)[0]?.id}/${service_type}`).then((response) => {
                setData(response.data.data);
                setNewData(response.data.data);
                setPending(false);
                console.log(response.data.data);

            }).catch((err) => {
                setPending(false);
                console.log(err);
            })
            setSlot_Date('')
        }

    }, [consultantIds, service_type])





    useEffect(() => {
        data?.filter((data) => data.id == updateId)?.map((data) => {
            setUpdateSlotDate(data.slot_date);
            setUpdateSlotTime(data.slot_time);
            setCurSlotId(data.id);
            setUpdateId(data.all_ids);
            setSlotStatus(data.status);
        })
    }, [updateId])

    const notifyStatus = () => toast.success('Slot status change Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyDelete = () => toast.success('Slots Deleted Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    // const handleStatus = () => {
    //     // let id = e.target.id;
    //     // let status = e.target.value;
    //     axios.post(`/slotStatus', {
    //         id: updateId,
    //         status: slotStatus,
    //     }, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "multipart/form-data"
    //         }
    //     }).then((response) => {
    //         if (response.status === 200) {
    //             notifyStatus();
    //             window.location.reload(false);
    //         }
    //     }).catch((err) => {

    //         console.log(err);
    //     })
    // }


    const handleUpdate = () => {
        setShowUpdate(false);
    }

    const handleShowDelete = (slot_ids, start_time, slot_date) => {

        const newObject = {
            id: slot_ids,
            start_time: start_time,
            end_time: endTimeOf(slot_ids, start_time),
            slot_date: slot_date
        };

        setDeleteId(newObject);
        setShowDelete(true);


    };

    const handleDelete = () => {
        const newObject = {
            id: '',
            start_time: '',
            end_time: '',
            slot_date: ''
        };
        setDeleteId(newObject);
        console.log(deleteId)
        setShowDelete(false);


    };


    const handleDeleteSlot = () => {

        axios.post(`/deleteSlot`, {
            id: deleteId.id

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                setPending(false);
                setDeleteId('')
                handleDelete();
                notifyDelete();
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);

            }
        }).catch((err) => {
            setPending(false);
            setDeleteId('')
            console.log(err);
        })
    }


    const notifyUpdate = () => toast.success('Slot update Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notify = () => toast.success('Slot add Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyErr = (err) => toast.error(`${err}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const endTimeOf = (slot_ids, start_time) => {
        const [hh, mm, ss] = start_time.split(':')?.map(Number);
        const idsArray = slot_ids.split(",");
        const idsCount = (idsArray.length * 15);
        const totalMinutes = hh * 60 + mm + idsCount;

        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:00`
    }

    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },

            {
                name: "Slot Id",
                selector: row => <span>{row.all_ids.split(",")?.map(id => parseInt(id.trim(), 10))[0]} - {row.all_ids.split(",")?.map(id => parseInt(id.trim(), 10)).slice(-1)}</span>,
                width: '200px',
            },

            {
                name: "Slot Date",
                selector: (row) => row.slot_date,
                width: '200px',

            },
            {
                name: "Slot Time",
                selector: row => <span>{row.slot_time} - {endTimeOf(row.all_ids, row.slot_time)}</span>,
                width: '250px',

            },
            {
                name: "Status",
                selector: row => <span>{row.status == 0 ? "Active" : <span className='bg-success text-white p-2' style={{ borderRadius: '10px' }}>Free Slot</span>}</span>,
                // <>
                //     <Form.Select aria-label="Default select example"
                //         value={row.status} id={row.id}
                //         onChange={(e) => handleStatus(e)}
                //     >

                //         <option value="0">Active</option>
                //         <option value="1">In-Active</option>

                //     </Form.Select>

                // </>,
                width: '200px'

            },

            {
                name: "Action",
                selector: (row) => <div className='d-flex' style={{ gap: '10px' }}>
                    <Button className='Add_btn_'
                        onClick={() => handleShowUpdate(row.id, row.all_ids, row.slot_time)}
                    ><FaEdit /></Button>
                    <Button className='Add_btn_' onClick={() => handleShowDelete(row.all_ids, row.slot_time, row.slot_date)}><FaTrashAlt /></Button>
                </div>,
                width: '200px',
            },
            {
                name: "Consulant Name",
                selector: (row) => row.consulant_name,
                width: '200px',

            },

            {
                name: "Slot Week",
                selector: (row) => row.slot_week,
                width: '200px',

            },


            {
                name: "Time Zone",
                selector: (row) => row.time_zone,
                width: '200px',
            },



        ],

    };



    const handleShowUpdate = (slot_id, slot_ids, start_time) => {

        setShowUpdate(true);
        setUpdateId(slot_id)
        const newObject = {
            ids: slot_ids,
            start_time: start_time,
            end_time: endTimeOf(slot_ids, start_time),

        }
        setUpdateData(newObject)


    }


    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true)
    
        axios.post(`/addSlot`, {
            consulant_id: consultantId,
            slot_date: slotDate,
            end_date: end_Date,
            start_time: startTime,
            end_time: endTime,
            // email: email,
            // phone: phone,
            // address: address,
            // time_zone: timeZone,
            // slot_range: slotRange

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {

                setDateData(response.data.data.date);
                setTimeData(response.data.data.start_time);
                setEndData(response.data.data.end_time);
                handleClose();
                handleMessageshow();
                setPending(false);


            }
        }).catch((err) => {
            setPending(false);
            handleClose();
            notifyErr(err.response.data.message);

            console.log(err);
        })

    }


    const onUpdate = () => {
        setPending(true);
        console.log({
            id: updateData.ids,
            status: slotStatus

        })

        axios.post(`/updateSlot`, {
            id: updateData.ids,
            status: slotStatus

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                handleUpdate();
                notifyUpdate();
                setPending(false);
                window.location.reload(true);

            }
        }).catch((err) => {

            console.log(err);
        })


    }

    const filterByDate = (date) => {
        // if (status == "") {
        //     setData(newData);
        // } else {
        //     setData(newData?.filter(data => data.status == status));
        // }
        if (date != '') {
            axios.get(`/getallSlots/${JSON.parse(adminAuth)[0]?.id == 1 ? consultantIds : JSON.parse(adminAuth)[0]?.id}/${service_type}/${date}`).then((response) => {
                setData(response.data.data);
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log(err);
            })
        } else {
            setData(newData);
        }
        setSlot_Date(date)

    }

    const handleConsultant = (type) => {
        setService_type(type);
        // getSlots(consultantId, timezone, type);

    }


    return (
        <>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

            <section id="main-content"
                style={pending ? { opacity: '0.5' } : { opacity: '1' }}
            >
                <ToastContainer />
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Slots</span>
                            <section className="card m-3">

                                <div className='d-flex align-content-center justify-content-between'>
                                    <div className='d-flex align-content-center gap-4'>
                                        {JSON.parse(adminAuth)[0].id == 1 ?
                                            <Form.Group className="p-2">
                                                <Form.Select aria-label="Default select example"
                                                    value={consultantIds}
                                                    onChange={(e) => setConsultantIds(e.target.value)}
                                                >
                                                    <option value="">Select Consultant First</option>
                                                    {allConsultant?.map((data) => {
                                                        return (
                                                            <option key={data.id} value={data.id}>{data.name}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            : null}

                                        <Form.Group className="p-2">

                                            <Form.Select aria-label="Default select example"
                                                value={service_type}
                                                onChange={(e) => handleConsultant(e.target.value)}
                                            >
                                                <option value="">Select Consultation Type</option>

                                                <option value="5">Free Consultation (15min)</option>
                                                <option value="12">Hypnosis session - (1.30hrs)</option>
                                                <option value="10">Hypno Gastric Band - (1.30hrs)</option>
                                                <option value="11">Hypno Birthing & Pregnancy- (1.30hrs)</option>
                                                <option value="9">Menopause- (2hrs)</option>
                                                <option value="7">Overcoming Addiction (1hr)</option>
                                                <option value="8">Overcoming Gambling  (1hr)</option>
                                                <option value="6">Smoking Cessation (1hr)</option>
                                                <option value="1">The Control System Consultation (2hrs)</option>
                                                <option value="2">The Control System (1hr)</option>
                                                <option value="3">Weight Release Programme 1st Session (2hrs)</option>
                                                <option value="4">Weight Release Sessions Further Sessions (1hr)</option>

                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="p-2">
                                            <Form.Select aria-label="Default select example"
                                                value={slot_Date}
                                                onChange={(e) => filterByDate(e.target.value)}
                                            >
                                                <option value="">Select Select Date</option>
                                                {[...new Set(newData?.map(item => item.slot_date))]?.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data}>{data}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>

                                    {/* <div className="selectoption mt-4">
                        <strong >Status :-</strong>
                        <select className="status select_status filter"
                            onChange={(e) => filterByStatus(e.target.value)}
                        >
                            <option value="0">Pending</option>
                            <option value="1">Completed</option>
                            <option value="2">Rejected</option>
                            <option value="">All</option>
                        </select>
                    </div> */}

                                    <div className='p-2' >
                                        <Button className='Add_btn_' style={{ float: "right" }}
                                            onClick={handleShow}
                                        >Add Slot</Button>
                                    </div>

                                </div>
                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />


                                    {data?.length == 0 ? <p>Info : Please Select Consultant and Consultation Type from above</p> : null}
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div >
                </section >
            </section >



            {/* add */}
            < Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Slot</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>


                        {JSON.parse(adminAuth)[0]?.id == 1 ?
                            <Form.Group className="col-md-12 pb-3">
                                <Form.Label>Consultant</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    value={consultantId} placeholder="Enter Address"
                                    {...register("consultant", {
                                        required: true,
                                        onChange: (e) => setConsultantId(e.target.value)
                                    })}
                                >
                                    <option value="" selected disabled>Please select consultant</option>
                                    {allConsultant?.map((data) => {
                                        return (
                                            <>
                                                <option value={data.id}>{data.name}</option>
                                            </>
                                        )
                                    })}

                                </Form.Select>
                                {errors.consultant && <div className='errMsg pt-2'>Please select consultant</div>}
                            </Form.Group>
                            : null}
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type='date'
                                value={slotDate} placeholder="Enter Range"
                                {...register("slot_date", {
                                    required: true,
                                    onChange: (e) => setSlotDate(e.target.value)
                                })}
                            />
                            {errors.slot_date && <div className='errMsg pt-2'>Please enter Slot Range</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type='date'
                                value={end_Date} placeholder="Enter Range"
                                {...register("end_date", {
                                    required: true,
                                    onChange: (e) => setEnd_Date(e.target.value)
                                })}
                            />
                            {errors.end_date && <div className='errMsg pt-2'>Please enter Slot Range</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control type='time'
                                value={startTime} placeholder=""
                                {...register("start_time", {
                                    required: true,
                                    onChange: (e) => setStartTime(e.target.value)
                                })}
                            />
                            {errors.start_time && <div className='errMsg pt-2'>Please enter start Time</div>}
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control type='time'
                                value={endTime} placeholder=""
                                {...register("end_time", {
                                    required: true,
                                    onChange: (e) => setEndTime(e.target.value)
                                })}
                            />
                            {errors.end_time && <div className='errMsg pt-2'>Please enter end Time</div>}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="Add_btn_" type="submit">
                            Add
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >



            {/* update */}
            < Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Update Slot</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>



                        {/* <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={consultantId} placeholder="Enter Address"

                                onChange={(e) => setConsultantId(e.target.value)}

                            >
                                <option>select</option>
                                {allConsultant?.map((data) => {
                                    return (
                                        <>
                                            <option value={data.id}>{data.name}</option>
                                        </>
                                    )
                                })}
                            </Form.Select>

                        </Form.Group> */}
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Id</Form.Label>
                            <Form.Control type='text'
                                value={updateId} placeholder="Enter Title"
                                disabled


                            />

                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Date</Form.Label>
                            <Form.Control type='date'
                                value={updateSlotDate} placeholder="Enter Title"
                                disabled


                            />

                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Time</Form.Label>
                            <Form.Control type='text'
                                value={`${updateSlotTime} - ${updateData.end_time}`}
                                // placeholder={`${updateSlotTime} ${endTimeShow}`}
                                disabled

                            />

                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Select aria-label="Default select example"
                                value={slotStatus}
                                onChange={(e) => setSlotStatus(e.target.value)}
                            >
                                <option value="0">Active</option>
                                <option value="1">Free Slot</option>
                            </Form.Select>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleUpdate}>
                            Close
                        </Button>
                        <Button className="Add_btn_" onClick={onUpdate}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >



            <Modal show={showDelete} onHide={handleDelete} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title> Are you sure you want to delete slots?</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <span> Slot <b>{deleteId.slot_date}</b> with Time range ({deleteId.start_time} - {deleteId.end_time})</span><br></br>
                    will be permanently deleted
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDelete}>
                        Close
                    </Button>
                    <Button className="Add_btn_ btn-danger" onClick={handleDeleteSlot}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showmessage} onHide={handleMessage} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Slot Added Successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Date : {dateData}, Time : {timeData} - {endData}
                </Modal.Body>
                <Modal.Footer>

                    <Button className="Add_btn_" onClick={handleMessage}>
                        ok
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default SlotsAdmin