import React from 'react';
import './admin/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, HashRouter } from 'react-router-dom';
import Backend from './admin/Backend';
import Dashboard from './admin/pages/Dashboard';
import AdminAbout from './admin/pages/About';
import AdminBlog from './admin/pages/Blog';
import AdminSEO from './admin/pages/SEO';
import AdminBanner from './admin/pages/Banner';
import AdminProduct from './admin/pages/Product';
import AdminClient from './admin/pages/Client';
import AdminContact from './admin/pages/Contact';
import AdminTherapies from './admin/pages/Therapies';
import Adminhyponsis from './admin/pages/Hypnosis';
import AdminProfile from './admin/pages/Profile';
import AdminSetting from './admin/pages/Setting'
import AddHypnosis from './admin/pages/AddHypnosis'
import UpdateHypnosis from './admin/pages/UpdateHypnosis';
import ProductsSold from './admin/pages/ProductsSold';
import Consultant from './admin/pages/ConsultantsAdmin';
import SlotsAdmin from './admin/pages/SlotsAdmin';
import TheControlSystemAdmin from './admin/pages/TheControlSystemAdmin';
import ThePathwayStillAdmin from './admin/pages/ThePathwayStillAdmin';
import ConcessionAdmin from './admin/pages/Consessions';
import PrivacyPolicies from './admin/pages/PrivacyPolicies';
import Appointments from './admin/pages/Appointments';
import Form2 from './admin/pages/Forms/Form2';
import Form3 from './admin/pages/Forms/Form3';
import Form4 from './admin/pages/Forms/Form4';
import Form6 from './admin/pages/Forms/Form6';
import Form5 from './admin/pages/Forms/Form5';
import ViewForm2 from './admin/pages/Forms/ViewForm2';
import ViewForm3 from './admin/pages/Forms/ViewForm3';
import ViewForm4 from './admin/pages/Forms/ViewForm4';
import ViewForm6 from './admin/pages/Forms/ViewForm6';
import ViewForm5 from './admin/pages/Forms/ViewForm5';
import CancelRequest from './admin/pages/CancelRequest';
import Login from './admin/pages/Login/Login';
import { useSelector } from 'react-redux';
import ProtectedRoute from './admin/ProtectedRoute';


function App() {

  const { auth } = useSelector((state) => state?.auth);

  return (
    <React.Fragment>
      <HashRouter>
        <Routes>
          {auth ?

            <Route exact path='/' element={<ProtectedRoute><Backend /></ProtectedRoute>}>
              <Route index element={<Dashboard />} />
              <Route exact path='/therapies' element={<AdminTherapies />} />
              <Route exact path='/product' element={<AdminProduct />} />
              <Route exact path='/banner' element={<AdminBanner />} />
              <Route exact path='/blog' element={<AdminBlog />} />
              <Route exact path='/seo' element={<AdminSEO />} />
              <Route exact path='/enquiries' element={<AdminContact />} />
              <Route exact path='/hypnosis' element={<Adminhyponsis />} />
              <Route exact path='/client' element={<AdminClient />} />
              <Route exact path='/about' element={<AdminAbout />} />
              <Route exact path='/profile' element={<AdminProfile />} />
              <Route exact path='/setting' element={<AdminSetting />} />
              <Route exact path='/addhypnosis' element={<AddHypnosis />} />
              <Route exact path='/updatehypnosis/:id' element={<UpdateHypnosis />} />
              <Route exact path='/appointments' element={<Appointments />} />
              <Route exact path='/cancel-request' element={<CancelRequest />} />
              <Route exact path='/products-sold' element={<ProductsSold />} />
              <Route exact path='/consultants' element={<Consultant />} />
              <Route exact path='/slots' element={<SlotsAdmin />} />
              <Route exact path='/the-control-system' element={<TheControlSystemAdmin />} />
              <Route exact path='/the-pathway-still' element={<ThePathwayStillAdmin />} />
              <Route exact path='/consessions' element={<ConcessionAdmin />} />
              <Route exact path='/privacy-policy' element={<PrivacyPolicies />} />
              <Route exact path='/form2' element={<Form2 />} />
              <Route exact path='/form3' element={<Form3 />} />
              <Route exact path='/form4' element={<Form4 />} />
              <Route exact path='/form5' element={<Form5 />} />
              <Route exact path='/form6' element={<Form6 />} />
              <Route exact path='/viewform2/:id' element={<ViewForm2 />} />
              <Route exact path='/viewform3/:id' element={<ViewForm3 />} />
              <Route exact path='/viewform4/:id' element={<ViewForm4 />} />
              <Route exact path='/viewform5/:id' element={<ViewForm5 />} />
              <Route exact path='/viewform6/:id' element={<ViewForm6 />} />

            </Route>

            :
            <Route exact path='' element={<Login />} />
          }
        </Routes>
      </HashRouter>
    </React.Fragment >
  );
}

export default App;
