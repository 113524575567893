import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTables from '../Datatable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import TextEditor from '../Editor/TextEditor';
import 'react-toastify/dist/ReactToastify.css';
// import { Helmet } from 'react-helmet';

const Blog = () => {

  // const api_url = process.env.REACT_APP_API_URL;

  const [blog, setBlog] = useState([]);
  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleUpdate = () => setShowUpdate(false);
  const handleClose = () => {
    setDeleteShow(false);
    setShowUpdate(false);
    setShow(false);
    setAddImage('');
    reset({
      name: "",
      image: "",
      content: "",
      title: "",
      seo_title: "",
      seo_description: "",
      canonical: "",
      robot: "",
      script: "",
      feature_image: "",
    })
  }
  const [pending, setPending] = useState(true);

  const [data, setData] = useState([]);

  // add
  const [title, setTitle] = useState('');
  const [addImage, setAddImage] = useState('');
  const [content, setContent] = useState('');
  const [category_id, setCategory] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [Description, setDescription] = useState('');
  const [Canonical, setCanonical] = useState('');
  const [Robot, setRobot] = useState('');
  const [Script, setScript] = useState('');
  const [Feature, setFeature] = useState('');

  // update
  const [Id, setId] = useState('');
  const [updateImg, setUpdateImg] = useState('');
  const [updatedname, setUpdatedName] = useState('');
  const [updateContent, setUpdateContent] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [allCategory, setAllCategory] = useState([]);
  const [updatedContent, setUpdatedContent] = useState('');
  const [updatedSeoTitle, setUpdatedSeoTitle] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [UpdatedCanonical, setUpdatedCanonical] = useState('');
  const [UpdatedRobot, setUpdatedRobot] = useState('');
  const [UpdatedScript, setUpdatedScript] = useState('');
  const [UpdatedFeature, setUpdatedFeature] = useState('');

  const [deleteIds, setDeleteIds] = useState('');

  const getBlogs = () => {
    axios.get(`/getAllBlog`).then((response) => {
      setBlog(response.data.data);

      console.log(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
    axios.get(`/getCategory`).then((response) => {
      console.log(response.data.data);
      setAllCategory(response.data.data);

    });
  }


  const handleShow = () => {
    setShow(true);
    // getProductName();

  };

  const notifyDelete = () => toast.success('Blog Delete Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleDeleteShow = (e) => {
    // setDeleteShow(true);
    // setDeleteIds(e);
    axios.post(`/blogDelete`, {
      id: e

    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setPending(false);
        notifyDelete();
        window.location.reload(false);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  };

  // blogDelete

  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`/singleBlog/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }

  useEffect(() => {
    data?.map((data) => {

      setUpdateImg(data.image);
      setUpdateContent(data.content);
      setId(data.id);
      setUpdatedName(data.pageName);
      setUpdatedTitle(data.title);


      setUpdatedSeoTitle(data.seo_title);
      setUpdatedDescription(data.seo_description);
      setUpdatedCanonical(data.canonical);
      setUpdatedRobot(data.robot);
      setUpdatedScript(data.script);
      setUpdatedFeature(data.feature_image);

    })

  }, [data])


  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.post(`/loanStatus`, {
      id: id,
      status: status,
    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        window.location.reload(false);
      }
    }).catch((err) => {

      console.log(err);
    })
  }



  const tableData = {
    columns: [
      {
        name: '#',
        // width: '50px',
        cell: (row, index) => index + 1
      },
      {
        name: 'Category Name',
        selector: (row) => row.category_id,
        width: "250px",
      },

      {
        name: 'Title',
        selector: (row) => row.title,
        width: "400px",
      },


      {
        name: "Image",
        selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/blogs/${row.image}`} alt="image" />,
        sortable: true,
        width: '150px',
      },
      // {
      //   name: 'Content',
      //   selector: 'content',
      //   width: "300px",
      // },

      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
          <Button className='Add_btn_' onClick={() => handleDeleteShow(row.id)}><FaTrashAlt /></Button>
        </div>,
        right: true,
        width: '150px',

      },

    ],

  };


  useEffect(() => {
    getBlogs();
  }, []);

  const notifyUpdate = () => toast.info('Blog update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notify = () => toast.info('Blog add Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });



  let validationRules = { Today: { required: true } };

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = () => {
    setPending(true)
    axios.post(`/addNewBlog`, {
      title: title,
      image: addImage,
      content: content,
      // id: Id,
      category_id, category_id,

      seo_title: seoTitle,
      seo_description: Description,
      canonical: Canonical,
      robot: Robot,
      script: Script,
      feature_image: Feature

    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setPending(false);
        notify();
        window.location.reload(false);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    formData.append("file", updatedImage);
    axios.post(`/updateBlog`, {
      id: Id,
      image: updatedImage,
      content: updatedContent,
      title: updatedTitle,

      seo_title: updatedSeoTitle,
      seo_description: updatedDescription,
      canonical: UpdatedCanonical,
      robot: UpdatedRobot,
      script: UpdatedScript,
      feature_image: UpdatedFeature
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }



  return (
    <>
      {/* <Helmet>
                <title>LoanWale | Loan</title>
            </Helmet>  */}

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
        <ToastContainer />
        <section className="wrapper">

          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Blog</span>
              <section className="card m-3">

                <div className='p-2' >
                  <Button className='Add_btn_' style={{ float: "right" }}
                    onClick={handleShow}
                  >Add Blog</Button>
                </div>


                <div className="main">
                  <DataTables data={blog} columns={tableData.columns} />

                </div>
              </section>

            </div>

          </div>
        </section>
      </section>
      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Blog</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Modal.Body>
            <Form.Group className=" col-sm-12 mb-3">
              {/* <Form.Label>Cleaning Requirements</Form.Label> */}

              <Form.Select
                value={category_id}
                {...register("product", {
                  onChange: (e) => setCategory(e.target.value),
                  required: 'Please select Category',
                  pattern: {
                    message: "Please select Category"
                  }
                })}

              >
                <option value="">Choose Blog Category</option>
                {allCategory?.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>{category.category_name}</option>
                  )
                })}
              </Form.Select>

              {errors.product && <span className='errMsg'>{errors.product.message}</span>}

            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Title"
                {...register("name", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>


            <Form.Group
              className="mb-3"

            >

              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                {...register("image", {
                  required: true,
                  onChange: (e) => setAddImage(e.target.files[0])
                })} />
              {errors.image && <div className='errMsg pt-2'>Please Provide Image</div>}

              <div className='text-center p-3'>
                <img src={addImage ? URL.createObjectURL(addImage) : "assets/Image_not_available.png"} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />
              </div>

            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Content</Form.Label>
              {/* <Form.Control as="textarea" rows={3}
              placeholder="Enter Content"
                value={content} 
                {...register("content", {
                  required: "Please Provide Content",
                 
                  onChange: (e) => setContent(e.target.value)
                })}
              /> */}
              <div className='form-control'>
                <TextEditor data={''} setUpdatedContent={setContent} validationRules={validationRules} />
              </div>

              {/* {errors.content && <div className='errMsg pt-2'>{errors.content.message}</div>} */}
            </Form.Group>


            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Seo Title</Form.Label>
              <Form.Control
                value={seoTitle} placeholder="Enter Title"
                {...register("title", {
                  required: true,
                  onChange: (e) => setSeoTitle(e.target.value)
                })}
              />
              {errors.title && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Seo Description</Form.Label>
              <Form.Control
                value={Description} placeholder="Enter Description"
                {...register("description", {
                  required: true,
                  onChange: (e) => setDescription(e.target.value)
                })}
              />
              {errors.description && <div className='errMsg pt-2'>Please Provide Description</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Canonical</Form.Label>
              <Form.Control
                value={Canonical} placeholder="Enter Canonical"
                {...register("canonical", {
                  required: true,
                  onChange: (e) => setCanonical(e.target.value)
                })}
              />
              {errors.canonical && <div className='errMsg pt-2'>Please Provide Canonical</div>}
            </Form.Group>


            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Robot</Form.Label>
              <Form.Select aria-label="Default select example"
                value={Robot}
                {...register("robot", {
                  required: true,
                  onChange: (e) => setRobot(e.target.value)
                })}

              >
                <option value="">Select Robot</option>
                <option value="1">Index/Follow</option>
                <option value="2">Index/No-follow</option>
                <option value="3">No-index/Follow</option>
                <option value="4">No-index,No-Follow</option>
              </Form.Select>

              {errors.robot && <div className='errMsg pt-2'>Please Select robot</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Feature Image url</Form.Label>
              <Form.Control
                value={Feature} placeholder="Enter Url"
                {...register("feature", {
                  required: true,
                  onChange: (e) => setFeature(e.target.value)
                })}
              />
              {errors.feature && <div className='errMsg pt-2'>Please Provide Image</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Script</Form.Label>
              <Form.Control
                value={Script} placeholder="Enter Script"
                {...register("script", {
                  required: true,
                  onChange: (e) => setScript(e.target.value)
                })}
              />
              {errors.script && <div className='errMsg pt-2'>Please Provide Script</div>}
            </Form.Group>



          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Blog</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>



            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"

            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                onChange={(e) => setUpdatedImage(e.target.files[0])}

              />

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/blogs/${updateImg}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />

            </div>


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Content</Form.Label>
              {/* <Form.Control as="textarea" rows={3}
                value={updateContent}
                onChange={(e) => setUpdateContent(e.target.value)}
              /> */}
              <div className='form-control'>
                <TextEditor data={updateContent} setUpdatedContent={setUpdatedContent} validationRules={validationRules} />
              </div>
            </Form.Group>



            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Seo Title</Form.Label>
              <Form.Control
                value={updatedSeoTitle}
                onChange={(e) => setUpdatedSeoTitle(e.target.value)}
              />
            </Form.Group>


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Seo Description</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={updatedDescription}
                onChange={(e) => setUpdatedDescription(e.target.value)}
              />
            </Form.Group>


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Seo canonical</Form.Label>
              <Form.Control
                value={UpdatedCanonical}
                onChange={(e) => setUpdatedCanonical(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Seo Feature-Image url</Form.Label>
              <Form.Control
                value={UpdatedFeature}
                onChange={(e) => setUpdatedFeature(e.target.value)}
              />
            </Form.Group>


            <Form.Group className="col-md-12 pb-3">
              <Form.Select aria-label="Default select example"
                value={UpdatedRobot}
                onChange={(e) => setUpdatedRobot(e.target.value)}
              >
                <option value="1">Index/Follow</option>
                <option value="2">Index/No-follow</option>
                <option value="3">No-index/Follow</option>
                <option value="4">No-index,No-Follow</option>
              </Form.Select>
            </Form.Group>


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Seo Script</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={UpdatedScript}
                onChange={(e) => setUpdatedScript(e.target.value)}
              />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



    </>
  )
}

export default Blog