import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';



const ThePathwayStillAdmin = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [file, setFile] = useState('');
    const [pending, setPending] = useState(true);
    const [title, setUpdatedTitle] = useState('');

    // function handleChange(e) {
    //     setFile(URL.createObjectURL(e.target.files[0]));
    // }

    const notify = () => toast.success("Edit SuccessFully");

    const getData = () => {
       axios.get(`/getPathwayStill`).then((response) => {
            setData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        });
    }

    useEffect(() => {
        getData();

    }, [])

    useEffect(() => {

        data?.map((admin) => {
            setUpdatedTitle(admin.title);
        })
    }, [data])


    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const handleUpdate = () => {

        const formData = new FormData();
        formData.append("file", file);

        axios.post(`/updatePathwaystill`, { content: updatedContent, image: file }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                window.location.reload(true);
            }

        })
    }

    let validationRules = { Today: { required: true } };

    return (
        <>
 <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <ToastContainer />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="cardSectionBorder">
                                <span className='p-3'>The Pathway Still</span>
                                <div className='p-3'>
                                    <TextEditor data={data?.map((data) => data.content).toString()} setUpdatedContent={setUpdatedContent} validationRules={validationRules} />
                                </div>
                                <div className='img_input p-3'>
                                    <Form>
                                        
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >

                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
                                                </div>

                                                <div className='col-lg-6 text-center'>
                                                    <Form.Label>Image Preview</Form.Label>
                                                    <div></div>
                                                    {file == "" ?
                                                        <>
                                                            {data?.map((img) => {
                                                                return (
                                                                    <img src={`/assets/pathwaystill/${img.image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <img src={URL.createObjectURL(file)} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />

                                                    }

                                                </div>
                                            </div>

                                        </Form.Group>
                                    </Form>

                                </div>

                                <hr></hr>
                                <div>
                                    <Button className='Add_btn_' onClick={handleUpdate}>Update</Button>
                                </div>


                            </section>

                        </div>
                    </div>
                </section>







            </section>
        </>
    )
}

export default ThePathwayStillAdmin