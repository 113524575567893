import { AiFillDashboard, AiOutlineForm } from 'react-icons/ai';
import { IoImageSharp } from 'react-icons/io5';
import { FaMicroblog, FaProductHunt } from 'react-icons/fa';
import { RiPsychotherapyFill } from 'react-icons/ri';
import { MdFmdBad, MdContactPhone, MdGroups2, MdMarkEmailUnread } from 'react-icons/md';
import { SiHypothesis, SiGnuprivacyguard } from 'react-icons/si';
import { BiUserCheck } from 'react-icons/bi';
export const SidebarItems = [
    {
        title: "Dashboard",
        url: "/",
        icon: <AiFillDashboard />,
    },
    {
        title: "Appointments",
        url: "/appointments",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Cancel Request",
        url: "/cancel-request",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Enquiries",
        url: "/enquiries",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Products sold",
        url: "/products-sold",
        icon: <FaMicroblog />,
    },
    {
        title: "Consultant",
        url: "/consultants",
        icon: <BiUserCheck />,
    },
    {
        title: "Slots",
        url: "/slots",
        icon: <MdMarkEmailUnread />
    },
    {
        title: "Products",
        url: "/product",
        icon: <FaMicroblog />,
    },
    {
        title: "Therapies",
        url: "/therapies",
        icon: <RiPsychotherapyFill />
    },
    {
        title: "Hypnosis",
        url: "/hypnosis",
        icon: <RiPsychotherapyFill />
    },
    {
        title: "The Control system",
        url: "/the-control-system",
        icon: <RiPsychotherapyFill />
    },
    {
        title: "Banner",
        url: "/banner",
        icon: <IoImageSharp />
    },
    {
        title: "Blog",
        url: "/blog",
        icon: <FaMicroblog />,
    },
    {
        title: "SEO",
        url: "/seo",
        icon: <FaMicroblog />,
    },
    {
        title: "About Us",
        url: "/about",
        icon: <MdFmdBad />
    },
    {
        title: "Client Reviews",
        url: "/client",
        icon: <MdGroups2 />
    },
    {
        title: "The Pathway Still",
        url: "/the-pathway-still",
        icon: <RiPsychotherapyFill />
    },
    // {
    //     title: "Consessions",
    //     url: "/consessions",
    //     icon: <SiHypothesis />
    // },
    // Privacy Policy
    {
        title: "Privacy Policy",
        url: "/privacy-policy",
        icon: <SiGnuprivacyguard />
    },
    {
        title: "Confidential Form",
        url: "/form2",
        icon: <AiOutlineForm />
    },
    {
        title: "Smoking Form",
        url: "/form3",
        icon: <AiOutlineForm />
    },
    {
        title: "Happiness Details Form",
        url: "/form4",
        icon: <AiOutlineForm />
    },
    {
        title: "Optional Modules Form",
        url: "/form5",
        icon: <AiOutlineForm />
    },
    {
        title: "Weight Release Form",
        url: "/form6",
        icon: <AiOutlineForm />
    },
]