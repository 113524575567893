import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaShare, FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const ProductsSold = () => {

    const api_url = process.env.REACT_APP_API_URL;


    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [mySpinner, setMySpinner] = useState(false);
    const [pending, setPending] = useState(true);


    const getData = () => {
       axios.get(`/getAllsoldProduct`).then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data);

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    useEffect(() => {
        getData();

    }, []);


    const successRefundNotify = () => toast.success("Refund Successful", { position: "top-center" });
    const successCancel = () => toast.success("Product Cancel Successfully", { position: "top-center" });
    const errMsg = (err) => toast.error(`${err}`, { position: "top-center" });


    const handleRefund = (id) => {
        setPending(true);
        axios.post(`/paymentRefund`, {
            id: id,

        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                successRefundNotify();
                setPending(false);
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            errMsg("Something went wrong");
            console.log(err);
        })
    }

    const handleCancel = (id) => {
        setPending(true);
        axios.post(`/cancelProduct`, {
            id: id,

        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                successCancel();
                setPending(false);
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            errMsg("Something went wrong");
            console.log(err);
        })
    }


    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },
            {
                name: "Booking Id",
                selector: (row) => row.booking_id,
                width: '150px',

            },

            {
                name: "Total Price",
                selector: (row) => row.total_price,

                width: '150px',
            },
            {
                name: "Transaction Id",
                selector: (row) => row.transaction_id,
                width: '150px',
            },

            {
                name: "Name",
                selector: (row) => `${row.first_name} ${row.last_name}`,
                width: '250px',

            },
            {
                name: "Email",
                selector: (row) => row.email,
                width: '250px',

            },
            {
                name: "Phone",
                selector: (row) => row.phone,
                width: '150px',

            },
            {
                name: "Address",
                selector: (row) => row.address,
                width: '150px',
            },
            {
                name: "Country",
                selector: (row) => row.country,
                width: '150px',
            },

            {
                name: "State",
                selector: (row) => row.state,
                width: '150px',
            },

            {
                name: "City",
                selector: (row) => row.city,
                width: '150px',
            },

            {
                name: "Cancel",
                selector: row => <>
                    {row.cancel_status == 0 ?
                        <Button className='bg-danger border-0' onClick={() => handleCancel(row.id)}>Cancel</Button>
                        : row.cancel_status == 2 ? <span className='bg-danger text-white p-3'>Canceled</span> : null}
                </>,
                width: '150px',
            },


            // dateSlotShow
            // timeSlotShow

            // {
            //     name: "Action",
            //     selector: row => <Button className='Add_btn_'
            //     // onClick={() => handleReplyShow(row.id)}
            //     ><FaShare /></Button>,
            //     right: true

            // },

            {
                name: "Refund",
                selector: row => <>
                    {row.refund_status == 1 && row.total_price > 0 ?
                        <Button className='Add_btn_' onClick={() => handleRefund(row.id)}>Refund</Button>
                        : row.refund_status == 2 ? <span className='bg-success text-white p-3'>Refunded</span> : null}
                </>,
                width: '150px',
            },

        ],

    };


    // 

    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.cancel_status == status));
        }

    }

    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

            <section id="main-content"
                style={pending ? { opacity: '0.5' } : { opacity: '1' }}
            >
                <ToastContainer />
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Products Sold</span>
                            <section className="card m-3">


                                <div className="selectoption mt-4">

                                    <strong >Status :-</strong>
                                    <select className="status select_status filter"
                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="2">Cancelled</option>


                                    </select>

                                </div>



                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>
        </>
    )
}

export default ProductsSold