import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaEdit, FaShare, FaTimes } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import DataTables from '../Datatable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';


const ConsultantsAdmin = () => {


    const api_url = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [consultant, setConsultant] = useState([]);
    const [newData, setNewData] = useState([]);
    const [mySpinner, setMySpinner] = useState(false);
    const [pending, setPending] = useState(true);
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [allTimeZones, setAllTimeZones] = useState([]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [slotRange, setSlotRange] = useState('');

    const [consultantId, setConsultantId] = useState('');
    const [updateName, setUpdateName] = useState('');
    const [updateEmail, setUpdateEmail] = useState('');
    const [updatePhone, setUpdatePhone] = useState('');
    const [updateAddress, setUpdateAddress] = useState('');
    const [updateTimeZone, setUpdateTimeZone] = useState('');
    const [updateSlotRange, setUpdateSlotRange] = useState('');

    const getData = () => {
       axios.get(`/getallconsultants`).then((response) => {
            setData(response.data.data);
            setNewData(response.data.data);
            setPending(false);
            console.log(response.data.data);

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

       axios.get(`/alltimeZones`).then((response) => {
            setAllTimeZones(response.data.data);
        }).catch((err) => {
            console.log(err)
        });


    }

    useEffect(() => {
        getData();

    }, []);


    useEffect(() => {
        data?.filter(data => data.id == consultantId)?.map((data) => {
            setUpdateName(data.name);
            setUpdateEmail(data.email);
            setUpdatePhone(data.phone);
            setUpdateAddress(data.address);
            setUpdateSlotRange(data.slot_range);
            setUpdateTimeZone(data.time_zone);
        })

    }, [consultantId])


    const handleClose = () => {
        // setDeleteShow(false);
        setShow(false);
        // setAddImage('');
        reset({
            name: "",
            email: "",
            phone: "",
            address: "",
            slot_range: '',
            timeZone: ''
        })
    }

    const handleUpdate = () => {
        setShowUpdate(false);
        setConsultantId('')
        setUpdateName("");
        setUpdateEmail('');
        setUpdatePhone('');
        setUpdateAddress('');
        setUpdateSlotRange('');
        setUpdateTimeZone('');
        // reset({
        //     name: "",
        //     email: "",
        //     phone: "",
        //     address: "",
        //     slot_range: '',
        //     timeZone: ''
        // })
    };


    const handleShow = () => {
        setShow(true);
        // getProductName();

    };
    // const handleDeleteShow = (e) => {
    //     // setDeleteShow(true);
    //     setDeleteIds(e);

    // };

    const handleShowUpdate = (id) => {
        setShowUpdate(true);
        setConsultantId(id)
        // getProductName();
        // axios.get(`/singleBlog/${id}`).then((response) => {
        //     setConsultant(response.data.data);
        // }).catch((err) => {

        //     console.log(err);
        // })

    }


    const notifyStatus = () => toast.success('Consultant status change Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post(`/consultantStatus`, {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notifyStatus();
                window.location.reload(false);
            }
        }).catch((err) => {

            console.log(err);
        })
    }


    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },
            {
                name: "id",
                selector: (row) => row.id,


            },
            {
                name: "Name",
                selector: (row) => row.name,
            },

            {
                name: "Time Zone",
                selector: (row) => row.time_zone,
            },

            {
                name: "Slot Range",
                selector: (row) => row.slot_range,
            },
            

            // consultantStatus
            {
                name: "Status",
                selector: row => <>
                    <Form.Select aria-label="Default select example"
                        value={row.status} id={row.id}
                        onChange={(e) => handleStatus(e)}
                    >

                        <option value="0">Active</option>
                        <option value="1">In-Active</option>

                    </Form.Select>

                </>

            },



            {
                name: "Action",
                selector: (row) => <Button className='Add_btn_'
                    onClick={() => handleShowUpdate(row.id)}
                ><FaEdit /></Button>,
            }
        ],
    };

    const notifyUpdate = () => toast.success('Consultant update Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    const notify = () => toast.success('Consultant add Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true)
        axios.post(`/addconsultant`, {
            name: name,
            email: email,
            phone: phone,
            address: address,
            time_zone: timeZone,
            slot_range: slotRange

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                setPending(false);
                notify();
                window.location.reload(false);

            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }


    const onUpdate = () => {
        setPending(true);
        const formData = new FormData();
        // formData.append("file", updatedImage);
        axios.post(`/updateconsultant`, {
            id: consultantId,
            name: updateName,
            email: updateEmail,
            phone: updatePhone,
            address: updateAddress,
            time_zone: updateTimeZone,
            slot_range: updateSlotRange

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notifyUpdate();
                handleUpdate();
                window.location.reload(false);

            }
        }).catch((err) => {

            console.log(err);
        })


    }


    return (
        <>
            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />

            <section id="main-content"
                style={pending ? { opacity: '0.5' } : { opacity: '1' }}
            >
                <ToastContainer />
                <section className="wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='p-3'>Consultant</span>
                            <section className="card m-3">

                                <div className='p-2' >
                                    <Button className='Add_btn_' style={{ float: "right" }}
                                        onClick={handleShow}
                                    >Add Consultant</Button>
                                </div>

                                {/* <div className="selectoption mt-4">

                        <strong >Status :-</strong>
                        <select className="status select_status filter"

                            onChange={(e) => filterByStatus(e.target.value)}
                        >

                            <option value="0">Pending</option>
                            <option value="1">Completed</option>
                            <option value="2">Rejected</option>
                            <option value="">All</option>
                        </select>

                    </div> */}

                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>


            {/* add */}
            <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Consultant</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                value={name} placeholder="Enter Name"
                                {...register("name", {
                                    required: true,
                                    onChange: (e) => setName(e.target.value)
                                })}
                            />
                            {errors.name && <div className='errMsg pt-2'>Please enter Name</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                value={email} placeholder="Enter email"
                                {...register("email", {
                                    required: true,
                                    onChange: (e) => setEmail(e.target.value)
                                })}
                            />
                            {errors.email && <div className='errMsg pt-2'>Please enter email</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                value={phone} placeholder="Enter phone"
                                {...register("phone", {
                                    required: true,
                                    onChange: (e) => setPhone(e.target.value)
                                })}
                            />
                            {errors.phone && <div className='errMsg pt-2'>Please enter Phone</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                value={address} placeholder="Enter Address"
                                {...register("address", {
                                    required: true,
                                    onChange: (e) => setAddress(e.target.value)
                                })}
                            />
                            {errors.address && <div className='errMsg pt-2'>Please enter Address</div>}
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={timeZone} placeholder="Enter Address"
                                {...register("timeZone", {
                                    required: true,
                                    onChange: (e) => setTimeZone(e.target.value)
                                })}
                            >
                                <option>select</option>
                                {allTimeZones?.map((data) => {
                                    return (
                                        <>
                                            <option value={data.tine_zone}>{data.tine_zone}</option>
                                        </>
                                    )
                                })}

                            </Form.Select>
                            {errors.timeZone && <div className='errMsg pt-2'>Please select timezone</div>}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Range</Form.Label>
                            <Form.Control
                                value={slotRange} placeholder="Enter Range"
                                {...register("slot_range", {
                                    required: true,
                                    onChange: (e) => setSlotRange(e.target.value)
                                })}
                            />
                            {errors.slot_range && <div className='errMsg pt-2'>Please enter Slot Range</div>}
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="Add_btn_" type="submit">
                            Add
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >



            {/* update */}
            < Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Update Consultant</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                value={updateName} placeholder="Enter Name"
                                // {...register("name", {
                                // required: true,
                                onChange={(e) => setUpdateName(e.target.value)}
                            // }                            })}
                            />
                            {/* {errors.name && <div className='errMsg pt-2'>Please enter Name</div>} */}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                value={updateEmail} placeholder="Enter email"
                                // {...register("email", {
                                // required: true,
                                onChange={(e) => setUpdateEmail(e.target.value)}
                            //  }                       })}
                            />
                            {/* {errors.email && <div className='errMsg pt-2'>Please enter email</div>} */}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                value={updatePhone} placeholder="Enter phone"
                                // {...register("phone", {
                                // required: true,
                                onChange={(e) => setUpdatePhone(e.target.value)}
                            //    }                             })}
                            />
                            {/* {errors.phone && <div className='errMsg pt-2'>Please enter Phone</div>} */}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                value={updateAddress} placeholder="Enter Address"
                                // {...register("address", {
                                // required: true,
                                onChange={(e) => setUpdateAddress(e.target.value)}
                            //  }                               })}
                            />
                            {/* {errors.address && <div className='errMsg pt-2'>Please enter Address</div>} */}
                        </Form.Group>

                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Time Zone</Form.Label>
                            <Form.Select aria-label="Default select example"
                                value={updateTimeZone} placeholder="Enter Address"
                                // {...register("timeZone", {
                                // required: true,
                                onChange={(e) => setUpdateTimeZone(e.target.value)}
                            // }                                })}
                            >
                                <option>select</option>
                                {allTimeZones?.map((data) => {
                                    return (
                                        <>
                                            <option value={data.tine_zone}>{data.tine_zone}</option>
                                        </>
                                    )
                                })}
                            </Form.Select>
                            {/* {errors.timeZone && <div className='errMsg pt-2'>Please select timezone</div>} */}
                        </Form.Group>
                        <Form.Group className="col-md-12 pb-3">
                            <Form.Label>Slot Range</Form.Label>
                            <Form.Control
                                value={updateSlotRange} placeholder="Enter Title"
                                // {...register("slot_range", {
                                //     required: true,
                                onChange={(e) => setUpdateSlotRange(e.target.value)}
                            // })}
                            />
                            {/* {errors.slot_range && <div className='errMsg pt-2'>Please enter Name</div>} */}
                        </Form.Group>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleUpdate}>
                            Close
                        </Button>
                        <Button className="Add_btn_" onClick={onUpdate}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >

        </>
    )
}

export default ConsultantsAdmin