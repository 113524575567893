import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTables from '../Datatable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Helmet } from 'react-helmet';

const Banner = () => {

  // const api_url = process.env.REACT_APP_API_URL;

  const [banner, setBanner] = useState([]);
  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleUpdate = () => setShowUpdate(false);
  const handleClose = () => {
    setDeleteShow(false);
    setShowUpdate(false);
    setShow(false);
    setAddImage('');
    reset({
      name : "",
      image : "",
      content : "",
      title : ""
  })
  }
  const [pending, setPending] = useState(true);

  const [data, setData] = useState([]);

  // add
  const [pageName, setName] = useState('');
  const [addImage, setAddImage] = useState('');
  const [content, setContent] = useState('');

  // update
  const [Id, setId] = useState('');
  const [updateImg, setUpdateImg] = useState('');
  const [updatedname, setUpdatedName] = useState('');
  const [updateContent, setUpdateContent] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');



  const [deleteIds, setDeleteIds] = useState('');

  const getBanners = () => {
   axios.get(`/getAdminBanner`).then((response) => {
      setBanner(response.data.data);
      console.log(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  }


  const handleShow = () => {
    setShow(true);
    // getProductName();

  };
  const handleDeleteShow = (e) => {
    setDeleteShow(true);
    setDeleteIds(e);

  };

  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`/singleBanner/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }

  useEffect(() => {
    data?.map((data) => {

      setUpdateImg(data.image);
      setUpdateContent(data.content);
      setId(data.id);
      setUpdatedName(data.pageName);
      setUpdatedTitle(data.title);
    })

  }, [data])


  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.post(`/loanStatus`, {
      id: id,
      status: status,
    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        window.location.reload(false);
      }
    }).catch((err) => {

      console.log(err);
    })
  }




  

  const tableData = {
    columns: [
      {
        name: '#',
        // width: '50px',
        cell: (row, index) => index + 1
      },
      {
        name: 'Page Name',
        selector: (row) => row.pageName,
        width: "150px",
      },
      {
        name: 'Title',
        selector: (row) => row.title,
        width: "150px",
      },


      {
        name: "Image",
        selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/banner/${row.image}`} alt="image" />,
        sortable: true,
        width: '150px',
      },
      {
        name: 'Content',
        selector: (row) => row.content,
        width: "300px",
      },
      
      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
         </div>,
        right: true
      },

    ],

  };


  useEffect(() => {
    getBanners();
  }, []);

  const notifyUpdate = () => toast.success('Banner update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
 

  const { register, handleSubmit,reset, formState: { errors } } = useForm();


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    formData.append("file", updatedImage);
    axios.post(`/updateBanner`, {
      id: Id,
      image: updatedImage,
      content: updateContent,
      title:updatedTitle
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }

  return (
    <>
        {/* <Helmet>
                <title>LoanWale | Loan</title>
            </Helmet>  */}

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
      <ToastContainer />
        <section className="wrapper">

          <div className="row">
            <div className="col-lg-12">
              <span className='p-3'>Banner</span>
              <section className="card m-3">

                {/* <div className='p-2' >
                  <Button className='Add_btn_' style={{float:"right"}} 
                    onClick={handleShow}
                  >Add Loan</Button>
                </div> */}


                <div className="main">
                  <DataTables data={banner} columns={tableData.columns}/>

                </div>
              </section>

            </div>

          </div>
        </section>
      </section>


   

      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{zIndex:"9999"}}>
        <Modal.Header closeButton>
          <Modal.Title>Update Banner</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>

            

            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"

            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                onChange={(e) => setUpdatedImage(e.target.files[0])}

              />

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/banner/${updateImg}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} alt="image" />

            </div>


            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Content</Form.Label>
              <Form.Control as="textarea" rows={3}
                value={updateContent}
                onChange={(e) => setUpdateContent(e.tarcategoget.value)}
              />
            </Form.Group>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_"  onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

   

    </>
  )
}

export default Banner